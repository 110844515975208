import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { Col, Row } from 'antd';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const MarketGallery = ({ photos }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	photos = photos.map(i => ({
		src: i.img.tmb.url,
		url: i.img.url,
		width: i.img.dimensions.width,
		height: i.img.dimensions.height
	}));

	return (
		<>
			<Gallery photos={photos} onClick={openLightbox} />

			{viewerIsOpen ? (
				<Lightbox
					mainSrc={photos[currentImage].url}
					nextSrc={photos[(currentImage + 1) % photos.length].url}
					prevSrc={photos[(currentImage + photos.length - 1) % photos.length].url}
					onCloseRequest={() => setViewerIsOpen(false)}
					onMovePrevRequest={() => setCurrentImage((currentImage + photos.length - 1) % photos.length)}
					onMoveNextRequest={() => setCurrentImage((currentImage + 1) % photos.length)}
				/>
			) : null}
		</>
	);
};

const MarketPage = () => {
	const [ sections, setSections ] = useState([]);
	const [ images, setImages ] = useState([]);
	const [ infos, setInfos ] = useState([]);
	const [ titleProducts, setTitleProducts ] = useState('');
	const [ imgProducts, setImgProducts ] = useState('');
	const [ products, setProducts ] = useState([]);

	const data = useStaticQuery(graphql`
	    query {
	      imageMarket: file(relativePath: { eq: "market.jpg" }) {
	        childImageSharp {
	          fluid(maxWidth: 1920) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X4bw8xEAANoKIm3o', { lang : 'fr-ch' })
		).then(response => {
			setSections(response.data.section);
			setImages(response.data.images_marche);
			setInfos(response.data.infos);
			setTitleProducts(response.data.titre_products.length ? response.data.titre_products[0].text : '');
			setImgProducts(response.data.legume_image_products.url ? response.data.legume_image_products.url : '');
			setProducts(response.data.labels);
		});
	}, []);

	return (
		<AppLayout>
			<SEO title="Le Marché des Mattines" />

			<div className="hero-image">
				<Img fluid={data.imageMarket.childImageSharp.fluid} />
			</div>

			<div>
				{sections.map((section, key) => (
					<div key={key} className="page-section">
						<div className="section-title">
							<h2>
								{section.titre.length ? section.titre[0].text : ''}
								{section.legume_image.url ? (
									<img src={section.legume_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								) : null}
							</h2>
							<div className="section-title-line" />
						</div>

						<div className="section-content">
							{section.paragraph.length ? (
								<div className="section-content-text">
									{section.sous_titre.length ? <h3>{section.sous_titre[0].text}</h3> : null}
									<div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(section.paragraph) }} />
								</div>
							) : null}

							{section.content_image.url ? (
								<div className="section-content-image">
									<img src={section.content_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								</div>
							) : null}
						</div>

						{key === 0 && (
							<div className="section-content">
								<MarketGallery photos={images} />
							</div>
						)}
					</div>
				))}

				{infos.map((info, key) => (
					<div key={key} className="page-section">
						<div className="section-title">
							<h2>
								{info.titre.length ? info.titre[0].text : ''}
								{info.legume_image.url ? (
									<img src={info.legume_image.url} alt={info.titre.length ? info.titre[0].text : ''} />
								) : null}
							</h2>
							<div className="section-title-line" />
						</div>

						<div className="section-content">
							{info.paragraph.length ? (
								<div className="section-content-text" style={{ flex: '0 1 40%' }}>
									<div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(info.paragraph) }} />
								</div>
							) : null}

							<div className="section-content-map">
								<Map center={[46.161200, 6.097130]} zoom={13} style={{ width: '100%', height: '100%' }} gestureHandling={true}>
									{(typeof window === 'undefined' ? null : () => {
										const L = require('leaflet');

										const mapMarker = new L.Icon({
											iconUrl: '/map-marker.png',
											iconRetinaUrl: '/map-marker.png',
											popupAnchor: null,
											shadowUrl: null,
											shadowSize: null,
											shadowAnchor: null,
											iconSize: new L.Point(63, 90),
											iconAnchor: new L.Point(32, 90)
										});

										return (
											<>
												<TileLayer
													attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
													url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
												/>
												<Marker icon={mapMarker} position={[46.161200, 6.097130]} />
											</>
										)
									})()}
								</Map>
							</div>
						</div>
					</div>
				))}

				<div className="page-section">
					<div className="section-title">
						<h2>
							{titleProducts}
							<img src={imgProducts} alt={titleProducts} />
						</h2>
						<div className="section-title-line" />
					</div>

					<div className="section-list">
						{products.map((p, i) => (
							<Row key={i} gutter={32} align="middle">
								<Col xs={{ span: 24, order: 2 }} md={{ span: 8, order: 1 }} lg={{ span: 6, order: 1 }}>
									<div className="preview">
										<img src={p.image_product.url ? p.image_product.url : ''} alt={p.titre_product.length ? <h3>{p.titre_product[0].text}</h3> : ''} />
									</div>
								</Col>
								<Col xs={{span: 24, order: 1 }} md={{ span: 16, order: 2 }} lg={{ span: 18, order: 2} }>
									{p.titre_product.length ? <h3>{p.titre_product[0].text}</h3> : null}
									{p.paragraph.length ? <div className="content" dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(p.paragraph) }} /> : null}
								</Col>
							</Row>
						))}
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default withTrans(MarketPage);
